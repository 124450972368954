import styled from "@emotion/styled";
import { hhBlackColor } from "../../../../../styles/themes/variables";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  background-color: ${hhBlackColor};
`;

export const Contents = styled.div`
  width: 320px;
  margin: 0 auto;
  padding: 40px 0;
  @media ${device.desktop} {
    width: 1230px;
    padding: 80px 0;
  }
`;

export const TitleWrapper = styled.div`
  @media ${device.desktop} {
    text-align: center;
    padding-bottom: 20px;
  }
`;

export const Title = styled.h2`
  color: white;
  font-size: 22px;
  line-height: 1.45;
  margin: 0 0 10px;
  @media ${device.desktop} {
    text-align: center;
    font-size: 31px;
  }
`;

export const Desc = styled.p`
  color: white;
  font-weight: 300;
  line-height: 1.6;
  font-size: 14px;
  letter-spacing: 0.05px;
  margin: 8px 0 16px;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 1.4;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 16px;
  @media ${device.desktop} {
    margin-top: 0;
    flex-direction: row;
    gap: 0 40px;
    justify-content: center;
    div:nth-of-type(4) {
      margin-left: 210px;
      margin-top: 40px;
    }
    div:nth-of-type(5) {
      margin-right: 210px;
      margin-top: 40px;
    }
  }
`;

export const Card = styled.div`
  color: white;
  background-color: #ffffff0d;
  //margin-top: 16px;
  height: fit-content;
  border-radius: 4px;
  padding: 16px 24px;
  @media ${device.desktop} {
    width: 370px;
    height: 352px;
    padding: 32px;
    margin-top: 20px;
  }
`;

export const CardNumber = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: white;
  @media ${device.desktop} {
    font-size: 31px;
  }
`;

export const CardTitle = styled.h3`
  font-size: 16px;
  margin: 16px 0 8px;
  letter-spacing: -0.4px;
  @media ${device.desktop} {
    font-size: 19px;
    margin: 16px 0 0;
  }
`;

export const CardDesc = styled.p`
  font-weight: 100;
  padding: 8px 0 0 0;
  line-height: 1.4;
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.05px;
  @media ${device.desktop} {
    line-height: 1.5;
    letter-spacing: -0.56px;
    margin: 30px 0 0;
    font-size: 16px;
  }
`;
export const CardDesc2 = styled(CardDesc)`
  @media ${device.desktop} {
    margin: 5px 0 0 !important;
  }
`;
