import * as S from "./LandingPageMentorOrganism.style";
import {
  landingMentorData,
  landingMentorEnding,
} from "../../../../models/landing/landing.models";
import Image from "next/image";

export default function LandingPageMentorOrganism() {
  return (
    <S.Wrapper>
      <S.Contents>
        <S.Title>멘토 라인업</S.Title>
        <S.CardWrapper>
          {landingMentorData.map((mentor) => (
            <S.Card key={mentor.mentor}>
              <S.MentorImg>
                <Image src={mentor.img} layout="intrinsic" />
              </S.MentorImg>
              <S.RightContent>
                {mentor.logo ? (
                  <S.LogoImg>
                    <Image src={mentor.logo} layout="intrinsic" />
                  </S.LogoImg>
                ) : (
                  <S.TextLogo text={mentor.logoText}>
                    {mentor.logoText}
                  </S.TextLogo>
                )}
                <hr />
                <S.Mentor>{mentor.mentor}</S.Mentor>
                <S.Company>{mentor.job}</S.Company>
                {mentor.career ? (
                  <>
                    <S.Career>{mentor.career}</S.Career>
                    <S.Desc>{mentor.desc}</S.Desc>
                  </>
                ) : (
                  <S.Desc>{mentor.desc}</S.Desc>
                )}
              </S.RightContent>
            </S.Card>
          ))}
        </S.CardWrapper>
        <S.Ending>
          이외에도 <b>총 70여 명의 시니어 개발자</b>가 프로젝트의 처음과 끝을
          함께하며, <br />
          <b>현업에서 인정 받는 항해 출신 개발자</b>가 각 주특기의 담당 학습
          코치로 상주합니다.
        </S.Ending>
      </S.Contents>
    </S.Wrapper>
  );
}
