import * as S from "./LandingPageHanghaeDifferenceOrganism.style";
import { landingHanghaeDifferenceData } from "../../../../models/landing/landing.models";
import { forwardRef } from "react";

function LandingPageHanghaeDifferenceOrganism(props, ref) {
  return (
    <S.Wrapper ref={ref}>
      <S.Contents>
        <S.TitleWrapper>
          <S.Title>항해는 이렇게 다릅니다.</S.Title>
          <S.Desc>
            항해는 혼자 공부하지 않습니다.
            <br />
            같은 수강생, 매니저들과 함께 99일의 파도를 헤쳐갑니다. 그래서 완주할
            수 있습니다.
          </S.Desc>
        </S.TitleWrapper>
        <S.CardWrapper>
          {landingHanghaeDifferenceData.map((difference) => (
            <S.Card key={difference.number}>
              <S.CardNumber>{difference.number}</S.CardNumber>
              <S.CardTitle>{difference.title}</S.CardTitle>
              <S.CardDesc>{difference.desc}</S.CardDesc>
              <S.CardDesc2>{difference.desc2}</S.CardDesc2>
            </S.Card>
          ))}
        </S.CardWrapper>
      </S.Contents>
    </S.Wrapper>
  );
}

export default forwardRef(LandingPageHanghaeDifferenceOrganism);
