import * as S from "./LandingPageLecturePriceOrganism.style";
import Image from "next/image";
import bookmark from "../../../../../public/images/main/bookmark.png";
import { forwardRef } from "react";

const LandingPageLecturePriceOrganism = (props, ref) => {
  return (
    <S.Container ref={ref}>
      <S.TitleContainer>
        <S.Title>수강료</S.Title>
        <S.Description>
          항해99는 누구나 열정만 있다면 개발자가
          <br />될 수 있는 기회가 주어져야 한다고 생각했습니다.
        </S.Description>
      </S.TitleContainer>
      <S.PaymentContainer>
        <S.PaymentCard inAdvance={true}>
          <S.PaymentTitle>선불제</S.PaymentTitle>
          <S.BeforePrice>
            400만원 <span>*월 33만원 대 (12개월 할부 시)</span>
          </S.BeforePrice>
          <S.TotalPriceContainer>
            <S.Divider />
            <S.TotalPrice>
              <span>시작 전 결제</span>
              <span>400만원</span>
            </S.TotalPrice>
          </S.TotalPriceContainer>
        </S.PaymentCard>
        <S.PaymentCard inAdvance={false}>
          <S.BookmarkWrapper>
            <Image src={bookmark} layout={"fill"} alt="수강료 추천" />
          </S.BookmarkWrapper>
          <S.PaymentTitle>후불제</S.PaymentTitle>
          <S.BeforePrice>
            100만원 <span>*월 8만원 대 (12개월 할부 시)</span>
          </S.BeforePrice>
          <S.TotalPriceContainer>
            <S.Divider />
            <S.TotalPrice>
              <span>시작 전 결제</span>
              <span>100만원</span>
            </S.TotalPrice>
            <S.TotalPrice>
              <span>취업 후 결제</span>
              <span>400만원</span>
            </S.TotalPrice>
            <S.PriceInfo>
              *취업 성공시에만 남은 금액을 지불합니다. <br />
              (4개월에 걸쳐 100만 원씩 분납)
            </S.PriceInfo>
          </S.TotalPriceContainer>
        </S.PaymentCard>
      </S.PaymentContainer>
    </S.Container>
  );
};

export default forwardRef(LandingPageLecturePriceOrganism);
