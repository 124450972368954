import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhBlackColor } from "../../../../../styles/themes/variables";

export const Wrapper = styled.section`
  background-color: ${hhBlackColor};
  padding: 40px 0;
  @media ${device.desktop} {
    padding: 80px 0;
  }
`;

export const Contents = styled.div`
  margin: 0 auto;
  max-width: 320px;
  @media ${device.desktop} {
    max-width: 1230px;
  }
`;

export const Title = styled.h3`
  color: white;
  font-size: 22px;
  line-height: 1.45;
  margin: 0;
  @media ${device.desktop} {
    font-size: 31px;
    text-align: center;
    margin: 0 0 40px;
  }
`;

export const CardWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;
  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
`;

export const Card = styled.div`
  padding: 24px 16px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media ${device.desktop} {
    padding: 32px 40px;
  }
`;

export const MentorImg = styled.div`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  @media ${device.desktop} {
    width: 168px;
    height: 168px;
  }
`;

export const RightContent = styled.div`
  width: 58%;
  hr {
    margin: 12px 0 6px;
    border: 1px solid #e4e4e4;
  }
  @media ${device.desktop} {
    hr {
      margin: 12px 0;
      border: 1px solid #e4e4e4;
    }
  }
`;

export const LogoImg = styled.div`
  width: 91px;
  @media ${device.desktop} {
    width: 160px;
  }
`;

export const TextLogo = styled.h3<{ text: string }>`
  color: ${(props) =>
    props.text.includes("이커머스")
      ? "#F03E3E"
      : props.text.includes("배달")
      ? "#15AABF"
      : "#623CF4"};
  font-size: 16px;
  line-height: 19px;
  margin: 5px 0 0 0;
  letter-spacing: -0.5px;
  @media ${device.desktop} {
    font-size: 24px;
    line-height: 29px;
    letter-spacing: initial;
  }
`;

export const Mentor = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 4px 0;
  @media ${device.desktop} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Company = styled.p`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.58px;
  margin: 0;
  @media ${device.desktop} {
    font-size: 18px;
  }
`;

export const Career = styled(Company)``;

export const Desc = styled.p`
  margin: 4px 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #81898f;
  letter-spacing: -0.58px;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Ending = styled.p`
  font-size: 14px;
  padding: 24px 0 0;
  line-height: 150%;
  margin: 0;
  color: white;
  font-weight: 200;
  br {
    display: none;
  }
  @media ${device.desktop} {
    text-align: center;
    padding: 56px 0 0 0;
    font-size: 16px;
    br {
      display: inherit;
    }
  }
`;
