import Image from "next/image";
import { preApplyBenefits } from "../../../../models/landing/landing.models";
import * as S from "./LandingPagePreApplyBannerCards.style";

export default function LandingPagePreApplyBannerCards() {
  return (
    <S.CardList>
      {preApplyBenefits.map((benefit) => (
        <S.Card key={benefit.title}>
          <S.CardIconWrapper>
            <Image
              layout="fill"
              objectFit="contain"
              src={`${benefit.iconUrl}`}
              alt={`${benefit.title}`}
            />
          </S.CardIconWrapper>
          <S.Benefit>
            <S.BenefitTitle>{benefit.title}</S.BenefitTitle>
            <S.BenefitInfo>{benefit.desc}</S.BenefitInfo>
          </S.Benefit>
        </S.Card>
      ))}
    </S.CardList>
  );
}
