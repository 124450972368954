import styled from "@emotion/styled";
import { hhBlackColor } from "../../../../../styles/themes/variables";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  background-color: ${hhBlackColor};
  height: 590px;
  @media ${device.desktop} {
    height: 700px;
    padding: 80px 0;
  }
`;

export const Contents = styled.div`
  max-width: 100%;
  @media ${device.desktop} {
    margin: 0 auto;
    max-width: 1230px;
  }
`;

export const Title = styled.h1`
  color: white;
  font-size: 22px;
  margin: 0 0 32px 20px;
  line-height: 1.45;
  @media ${device.desktop} {
    text-align: center;
    font-size: 32px;
    margin: 0 0 64px;
    br {
      display: none;
    }
  }
`;

export const Images = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    display: none;
  }
  div:first-of-type {
    margin-left: 20px;
  }
  div:last-of-type {
    margin-right: 20px;
  }
  @media ${device.desktop} {
    div:first-of-type {
      margin-left: 0;
    }
    div:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 296px;
  margin-right: 16px;
  @media ${device.desktop} {
    width: 368px;
    margin-right: 50px;
  }
  h2 {
    font-size: 18px;
    margin: 24px 0 15px;
    @media ${device.desktop} {
      font-size: 22px;
    }
  }
  p {
    font-weight: 100;
    white-space: pre-wrap;
    line-height: 1.6;
    letter-spacing: -0.56px;
    margin: 0;
    font-size: 14px;
    @media ${device.desktop} {
      line-height: 1.4;
      font-size: 16px;
    }
  }
`;

export const Image = styled.div`
  position: relative;
  width: 296px;
  height: 162px;
  margin: 0 !important;
  @media ${device.desktop} {
    width: 368px;
    height: 200px;
  }
`;
