import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const ChannelTalkButton = styled.button`
  border: none;
  background-color: transparent;
  position: fixed;
  bottom: 64px;
  right: 5%;
  cursor: pointer;
  width: 80px;
  height: 80px;

  @media ${device.desktop} {
    width: 120px;
    height: 120px;
  }
`;
