import * as S from "./LandingPageFloatingCta.style";
import { useAtom } from "jotai";
import { onlineUserIdAtom } from "../../../../states/user";
import { getCookie } from "../../../../businessLogics/util-browser";
import { useRouter } from "next/router";
import { goToApply } from "../../../../businessLogics/application";
import { useLogout } from "../../../../businessLogics/login/login";

export default function LandingPageFloatingCta() {
  const [onlineUserId] = useAtom(onlineUserIdAtom);
  const router = useRouter();
  const logout = useLogout();
  return (
    <S.Wrapper>
      <S.Contents>
        {onlineUserId !== "" && (
          <S.LogoutButton onClick={logout}>로그아웃 하기</S.LogoutButton>
        )}
        <S.ApplyButton onClick={() => goToApply(router)}>
          참가 신청하기
        </S.ApplyButton>
      </S.Contents>
    </S.Wrapper>
  );
}
