import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  background-color: #f1f1f1;
  padding: 40px 0;

  @media ${device.desktop} {
    padding: 80px 0;
  }
`;

export const Contents = styled.div`
  margin: 0 auto;
  max-width: 1230px;
`;

export const Title = styled.h2`
  font-size: 22px;
  line-height: 1.45;
  margin: 0;
  text-align: center;
  @media ${device.desktop} {
    text-align: inherit;
    font-size: 31px;
    br {
      display: none;
    }
  }
`;

export const CompanyWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  div:first-of-type {
    margin-left: 20px;
  }
  div:last-of-type {
    margin-right: 20px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    column-gap: 24px;
    div:first-of-type {
      margin-left: 0;
    }
    div:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Company = styled.div`
  position: relative;
  height: 350px;
  width: 275px;
  flex: 0 0 auto;
  margin: 20px 16px 0 0;
  scroll-snap-align: center;
  padding: 15px;
  background-image: linear-gradient(to top, #ffffff, #f1f1f1);
  border-radius: 4px;
  @media ${device.desktop} {
    display: inherit;
    height: 318px;
    width: 603px;
    padding: 26px 20px;
    margin: 36px 0 0 0;
    box-sizing: border-box;
    justify-self: flex-start;
    grid-template-rows: 0.5fr 1fr 2fr;
  }
    h3 {
      font-size: 14px;
      @media ${device.desktop} {
        font-size: 19px;
        margin-bottom: 15px;
      }
    }
    p {
      font-size: 14px;
      @media ${device.desktop} {
        font-size: 16px;
        
      }
    }
    p:first-of-type {
      font-weight: bold;
      margin-top: 20px;
      white-space: pre-wrap;
      word-break: keep-all;
      letter-spacing: -0.48px;
      @media ${device.desktop} {
        margin-top:40px;
        white-space: nowrap;
        letter-spacing: inherit;
      }
    }
    p:nth-of-type(2) {
      margin-top: 25px;
      font-weight: 200;
      letter-spacing: -0.48px;
      line-height: 1.5;
      @media ${device.desktop} {
        line-height: 1.4;
        margin-top:0;
        font-weight: 300;
        letter-spacing: inherit;
        word-break: keep-all;
      }
    }
  }
`;

export const Logo = styled.div`
  width: 80%;
  height: 50px;
  margin-top: 20px;
  @media ${device.desktop} {
    width: 170px;
    height: 30px;
    margin-top: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  max-width: 320px;
`;

export const MoreButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  background-color: #e4e4e4;
  display: flex;
  border: none;
  font-weight: bold;
  align-items: center;
  padding: 9px 24px;
  margin-top: 16px;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  color: black;
  @media ${device.desktop} {
    margin: 30px auto 0;
    width: fit-content;
    height: 52px;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    padding: 18px 24px;
  }
`;

export const DropdownIcon = styled.div<{ showMoreComapny: boolean }>`
  width: 15px;
  margin-left: 5px;
  padding-top: 3px;
  transform: ${(props) => (props.showMoreComapny ? "" : "rotate(90deg)")};
`;

export const DropdownIconMobile = styled.div`
  width: 15px;
  margin-left: 5px;
  padding-top: 3px;
`;
