import { useAtom } from "jotai";
import Image from "next/image";
import { useEffect } from "react";
import { bootChannelTalk } from "../../../../../lib/channelTalk/boot";
import channeltalk from "../../../../../static/images/icons/channel_talk_btn.png";
import { onlineUserIdAtom } from "../../../../states/user";
import * as S from "./ChannelTalkBtn.style";

export default function ChannelTalkBtn() {
  const [onlineUserId] = useAtom(onlineUserIdAtom);
  useEffect(() => {
    bootChannelTalk(onlineUserId);
  }, [onlineUserId]);

  return (
    <S.ChannelTalkButton
      className="channel-button"
      // onClick={onClickChannelTalkBtn}
    >
      <Image
        src={channeltalk}
        layout="fill"
        objectFit="contain"
        alt={"channelTalkBtn"}
      />
    </S.ChannelTalkButton>
  );
}
