import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import {
  fontNanumHandWritingDaughter,
  fontPretendard,
  hhMainColor,
} from "../../../../../styles/themes/variables";

export const ModalBackground = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
`;

export const Modal = styled.article`
  font-family: ${fontPretendard};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 312px;
  height: 444px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  padding: 8px;
  justify-content: center;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media ${device.desktop} {
    height: 684px;
    width: 480px;
  }
`;

export const ModalClose = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 25px;
  @media ${device.desktop} {
    top: 30px;
    right: 25px;
  }
`;

export const CloseImg = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  @media ${device.desktop} {
    width: 20px;
    height: 20px;
  }
`;

export const AnimationText = styled.p`
  color: white;
  font-weight: 400;
  z-index: 100;
  position: absolute;
  transition: opacity 1s;
  text-shadow: 0 4.6px 10.3px rgb(0 0 0 / 83%);
  letter-spacing: -0.8px;
  font-size: 30px;
  top: 88px;
  font-family: ${fontNanumHandWritingDaughter};
  word-break: keep-all;

  //animation: fade-inout 1.1s infinite alternate;
  //-moz-animation: fade-inout 1.1s infinite alternate; /* Firefox */
  //-webkit-animation: fade-inout 1.1s infinite alternate; /* Safari and Chrome */
  //-o-animation: fade-inout 1.1s infinite alternate;

  @keyframes fade-inout {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fade-inout {
    /* Firefox */
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-inout {
    /* Safari and Chrome */
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fade-inout {
    /* Opera */
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media ${device.desktop} {
    top: 130px;
    font-size: 50px;
  }
`;

export const BottomText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  color: #e4e4e4;
  margin-top: 230px;
  p {
    font-size: 12px;
    margin-bottom: 30px;
    line-height: 1.6;
    text-align: center;
  }
  @media ${device.desktop} {
    margin-top: 300px;
    p {
      font-size: 19px;
      margin: 50px 0 49px;
      line-height: 1.6;
      text-align: center;
    }
  }
`;

export const ApplyCTA = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${hhMainColor};
  z-index: 100;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  padding: 12px 30px;
  @media ${device.desktop} {
    font-size: 19px;
    padding: 14px 30px;
  }
`;

export const Arrow = styled.div`
  position: relative;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  @media ${device.desktop} {
    width: 27.6px;
    height: 27.6px;
  }
`;
