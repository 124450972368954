import Head from "next/head";
import { useEffect, useRef, useState } from "react";
import ChannelTalkBtn from "../src/components/atoms/Common/ChannelTalkBtn";
import LandingPageTemplate from "../src/components/templates/LandingPageTemplate";
import LandingPageLnbOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageLnbOrganism";
import LandingPageIntroOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageIntroOrganism";
import LandingPageFirstBodyOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageFirstBodyOrganism";
import LandingPageSecondBodyOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageSecondBodyOrganism";
import LandingPageThirdBodyOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageThirdBodyOrganism.";
import LandingPageFirstBannerOraganism from "../src/components/organisms/LandingPageOranisms/LandingPageFirstBannerOraganism";
import LandingPageFourthBodyOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageFourthBodyOrganism";
import LandingPageJoinedCompaniesOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageJoinedCompaniesOrganism";
import LandingPageHanghaeDifferenceOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageHanghaeDifferenceOrganism";
import LandingPageBestPartnersOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageBestPartnersOrganism";
import LandingPagePortOraganism from "../src/components/organisms/LandingPageOranisms/LandingPagePortOraganism";
import LandingPageMentorOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageMentorOrganism";
import LandingPageCurriculumOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageCurriculumOrganism";
import LandingPageCurriSprintOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageCurriSprintOrganism";
import LandingPagePortfoliosOrganism from "../src/components/organisms/LandingPageOranisms/LandingPagePortfoliosOrganism";
import LandingPageCareerSupportOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageCareerSupportOrganism";
import LandingPageLecturePriceOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageLecturePriceOrganism";
import LandingPageCompanyRecommendOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageCompanyRecommendOrganism";
import LandingPageQuestionOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageQuestionOrganism";
import LandingPageSecondBannerOrganism from "../src/components/organisms/LandingPageOranisms/LandingPageSecondBannerOrganism";
import LandingPageApplyOraganism from "../src/components/organisms/LandingPageOranisms/LandingPageApplyOraganism";
import Footer from "../src/components/molecules/Footer";
import { sendLog } from "../lib/logging";
import { useWindowScrollY } from "../src/businessLogics/util-browser";
import LandingPageFloatingCta from "../src/components/organisms/LandingPageOranisms/LandingPageFloatingCta";
import { curriculumRef } from "../src/components/organisms/LandingPageOranisms/LandingPageCurriculumOrganism/LandingPageCurriculumOrganism";
import { useAtom } from "jotai";
import {
  isAlreadyPopupAtom,
  orientationModalAtom,
} from "../src/states/landing";
import LandingPagePreApplyBannerOrganism from "../src/components/organisms/LandingPageOranisms/LandingPagePreApplyBannerOrganism";

export default function Home() {
  const hanghaeDiffierenceDiv = useRef<HTMLInputElement>(null);
  const paymentInfoDiv = useRef<HTMLInputElement>(null);

  const hanghaeDiffierenceTop = hanghaeDiffierenceDiv.current?.offsetTop;
  const paymentInfoTop = paymentInfoDiv.current?.offsetTop;
  const curriculumnTop = curriculumRef?.current?.offsetTop;
  const scrollY = useWindowScrollY();

  const [, setOrientationModal] = useAtom(orientationModalAtom);
  const [isAlreadyPopup, setIsAlreadyPopup] = useAtom(isAlreadyPopupAtom);

  const [scroll40log, setScroll40Log] = useState(false);
  const [scroll80log, setScroll80Log] = useState(false);

  const now = new Date();
  const endDate = new Date(2022, 8, 26, 12);
  const showModal = now < endDate;

  useEffect(() => {
    // 이미 로그를 다찍었으면 실행 X
    const innerHeight = window.innerHeight;
    if (scroll40log && scroll80log) return;

    if (scrollY + innerHeight >= hanghaeDiffierenceTop && !scroll40log) {
      sendLog("hh_mainPage_scroll_40", {});
      setScroll40Log(true);
      return;
    }

    if (scrollY + innerHeight >= paymentInfoTop && !scroll80log) {
      sendLog("hh_mainPage_scroll_80", {});
      setScroll80Log(true);
      return;
    }
  }, [scrollY]);

  useEffect(() => {
    // 커리큘럼 안내 div 도달시 사전 설명회 팝업 한번만 띄우기
    const innerHeight = window.innerHeight;
    if (isAlreadyPopup) return;
    if (!isAlreadyPopup && scrollY + innerHeight >= curriculumnTop) {
      showModal && setOrientationModal(true);
      setIsAlreadyPopup(true);
    }
  }, [scrollY]);

  return (
    <>
      <Head>
        <title>항해99 | 코딩 부트캠프 | 비전공자 개발자 취업</title>
      </Head>

      <LandingPageTemplate
        lnb={<LandingPageLnbOrganism />}
        intro={<LandingPageIntroOrganism />}
        preApplyBanner={<LandingPagePreApplyBannerOrganism />}
        firstBody={<LandingPageFirstBodyOrganism />}
        secondBody={<LandingPageSecondBodyOrganism />}
        thirdBody={<LandingPageThirdBodyOrganism />}
        firstBanner={<LandingPageFirstBannerOraganism />}
        fourthBody={<LandingPageFourthBodyOrganism />}
        joinedCompanies={<LandingPageJoinedCompaniesOrganism />}
        hanghaeDifference={
          <LandingPageHanghaeDifferenceOrganism ref={hanghaeDiffierenceDiv} />
        }
        bestPartners={<LandingPageBestPartnersOrganism />}
        port={<LandingPagePortOraganism />}
        mentor={<LandingPageMentorOrganism />}
        curriculum={<LandingPageCurriculumOrganism />}
        curriSprint={<LandingPageCurriSprintOrganism />}
        portfolios={<LandingPagePortfoliosOrganism />}
        careerSupport={<LandingPageCareerSupportOrganism />}
        // secondBanner={<LandingPageSecondBannerOrganism />}
        apply={<LandingPageApplyOraganism />}
        lecturePrice={<LandingPageLecturePriceOrganism ref={paymentInfoDiv} />}
        companyRecommend={<LandingPageCompanyRecommendOrganism />}
        question={<LandingPageQuestionOrganism />}
        footer={<Footer />}
        floatingCta={<LandingPageFloatingCta />}
      />
      <ChannelTalkBtn />
    </>
  );
}
