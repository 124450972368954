import * as S from "./LandingPageFirstBannerOraganism.style";
import Image from "next/image";
import { landingFirstBannerData } from "../../../../models/landing/landing.models";
import { useInnerWidth } from "../../../../businessLogics/util-browser";

export default function LandingPageFirstBannerOraganism() {
  const innerWidth = useInnerWidth();
  return (
    <S.Wrapper>
      <Image
        src={
          innerWidth >= 1024
            ? `${process.env.CDN_BASE_URL}/hanghae99/stat_bg.png`
            : `${process.env.CDN_BASE_URL}/hanghae99/stat_bg_mobile.png`
        }
        layout="fill"
        objectFit="cover"
        priority={true}
      />
      <S.TextWrapper>
        {landingFirstBannerData.map((data) => (
          <S.Text key={data.per}>
            <S.Title>{data.per}</S.Title>
            <S.Desc>
              <span>{data.title}</span> <br />
              <span>{data.nonMajor}</span>
            </S.Desc>
          </S.Text>
        ))}
      </S.TextWrapper>
    </S.Wrapper>
  );
}
