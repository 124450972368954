import Image from "next/image";
import * as S from "./LandingPageLnbOrganism.style";
import logo from "/static/images/logo/logo.png";
import { useAtom } from "jotai";
import { onlineUserIdAtom } from "../../../../states/user";
import { useRouter } from "next/router";
import { goToApply } from "../../../../businessLogics/application";

import {
  setCookie,
  useWindowScrollY,
} from "../../../../businessLogics/util-browser";
import {
  sectionId,
  useActiveSection,
} from "../../../../businessLogics/landing";
import { useLogout } from "../../../../businessLogics/login/login";

export default function LandingPageLnbOrganism() {
  const router = useRouter();
  const scrollY = useWindowScrollY();
  const activeSection = useActiveSection();
  const [onlineUserId] = useAtom(onlineUserIdAtom);
  const logout = useLogout();
  const scrollToElement = (id) => {
    const destination = document.getElementById(id).offsetTop - 35;
    window.scrollTo({ top: destination, behavior: "smooth" });
  };

  return (
    <S.Wrapper scrollY={scrollY}>
      <S.LnbContents>
        <S.Logo scrollY={scrollY}>
          <Image src={logo} layout="intrinsic" />
        </S.Logo>
        <S.MobileContents scrollY={scrollY}>
          {Object.keys(sectionId).map((id) => (
            <S.Contents
              onClick={() => scrollToElement(sectionId[id])}
              key={id}
              scrollY={scrollY}
              active={activeSection === id}
              text={id}
            >
              {sectionId[id]}
            </S.Contents>
          ))}
        </S.MobileContents>
        <S.RightSide scrollY={scrollY}>
          {Object.keys(sectionId).map((id) => (
            <S.Contents
              onClick={() => scrollToElement(sectionId[id])}
              key={id}
              scrollY={scrollY}
              active={activeSection === id}
              text={id}
            >
              {sectionId[id]}
            </S.Contents>
          ))}
          {onlineUserId !== "" && (
            <S.LnbButton onClick={() => logout()}>로그아웃 하기</S.LnbButton>
          )}
          <S.LnbButton onClick={() => goToApply(router)}>
            참가 신청하기
          </S.LnbButton>
        </S.RightSide>
      </S.LnbContents>
    </S.Wrapper>
  );
}
