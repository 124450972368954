import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhBlackColor } from "../../../../../styles/themes/variables";

export const Wrapper = styled.section`
  padding-bottom: 64px;
  position: relative;
  background-color: ${hhBlackColor};
  @media ${device.desktop} {
    height: 286px;
    padding: 0;
    background-color: inherit;
  }
`;

export const BackgroundImage = styled.div`
  display: none;
  @media ${device.desktop} {
    display: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const Contents = styled.div`
  color: white;
  position: relative;
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  @media ${device.desktop} {
    max-width: 1230px;
    padding: 64px 0;
    flex-direction: row;
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  margin-bottom: 8px;
  @media ${device.desktop} {
    font-size: 31px;
    line-height: normal;
  }
`;

export const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    width: 635px;
  }
`;

export const Desc = styled.p`
  color: white;
  font-weight: 100;
  font-size: 14px;
  margin: 0;
  padding-bottom: 12px;
  line-height: 1.6;

  @media ${device.desktop} {
    font-size: 16px;
    line-height: 1.4;
    white-space: pre-wrap;
  }
`;
