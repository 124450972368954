import * as S from "./LandingPageOrientationModalOrganism.style";
import { useAtom } from "jotai";
import { orientationModalAtom } from "../../../../states/landing";
import OutsideClickedWrapper from "../../../atoms/Common/OutsideClickedWrapper";
import Image from "next/image";
import closeIcon from "../../../../../static/images/modal/close-icon.png";
import { useEffect, useRef, useState } from "react";

export default function LandingPageOrientationModalOrganism() {
  const [modal, setModal] = useAtom(orientationModalAtom);
  const otDate = "9/26(월) ";
  const otTime = "오후 8시 ~ 오후 9시";
  const otTextList = [
    "문과 출신 비전공자",
    "플젝 경험 없는 컴공생",
    "국비과정 수료생",
    "35살 비전공자",
    "독학 2개월차",
  ];
  const [otIdx, setOtIdx] = useState(0);
  const idxRef = useRef(otIdx);
  idxRef.current = otIdx;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!modal) {
        return;
      }
      if (idxRef.current < otTextList.length - 1) {
        console.log("!!!");
        setOtIdx((prevIdx) => prevIdx + 1);
      } else {
        setOtIdx(0);
      }
    }, 2195);
    return () => clearTimeout(timer);
  }, [otIdx, modal]);

  return (
    <>
      {modal ? (
        <S.ModalBackground>
          <OutsideClickedWrapper setState={setModal}>
            <S.Modal>
              <Image
                src={`${process.env.CDN_BASE_URL}/hanghae99/ot_modal.png`}
                layout="fill"
                objectFit="contain"
                priority={true}
              />
              <S.ModalClose>
                <S.CloseImg>
                  <Image
                    src={closeIcon}
                    layout="fill"
                    objectFit="contain"
                    alt="close-icon"
                    onClick={() => setModal(false)}
                  />
                </S.CloseImg>
              </S.ModalClose>
              <S.AnimationText>{otTextList[otIdx]}</S.AnimationText>
              <S.BottomText>
                <p>
                  일시 : {otDate}
                  {otTime} <br />
                  장소 : Zoom(선착순 마감)
                </p>
              </S.BottomText>
              <S.ApplyCTA onClick={() => window.open("/v2/orientation")}>
                사전 설명회 신청하기
                <S.Arrow>
                  <Image
                    src={"/images/icons/modal_right_arrow.png"}
                    layout="fill"
                    objectFit="contain"
                  />
                </S.Arrow>
              </S.ApplyCTA>
            </S.Modal>
          </OutsideClickedWrapper>
        </S.ModalBackground>
      ) : null}
    </>
  );
}
