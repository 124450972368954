import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Container = styled.div`
  display: grid;
  gap: 24px;
  padding: 40px 0;
  background-color: #1d1e1f;
  justify-content: center;
  background-image: linear-gradient(to bottom, #343535, #1d1e1f 40%);
  @media ${device.desktop} {
    padding: 70px 0;
    gap: 40px;
  }
`;
export const TitleContainer = styled.div`
  display: grid;
  gap: 8px;
  padding: 0 20px;
  @media ${device.desktop} {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-family: "SpoqaHanSans", "Nanum Gothic", serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  letter-spacing: 0.66px;
  color: #ffffff;
  @media screen and ${device.tablet} {
    font-size: 31px;
    text-align: center;
  }
`;
export const Description = styled.div`
  font-family: "Pretendard", "Nanum Gothic", serif;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 300;
  color: white;
  @media screen and ${device.tablet} {
    font-size: 17px;
    text-align: center;
  }
`;

export const Carousel = styled.div`
  overflow-x: hidden;
  @media ${device.desktop} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const Arrow = styled.div<{ isActive: boolean }>`
  display: none;

  @media ${device.desktop} {
    display: inherit;
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 78px;
  height: 50%;
  padding: 0 15px;

  cursor: ${(props) => (props.isActive ? "pointer" : "default")};

  text-align: center;

  :before,
  :after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
`;

export const ArrowSVG = styled.svg``;

export const ArrowPath = styled.path<{ isActive: boolean }>`
  stroke: ${(props) => (props.isActive ? "white" : "#888888")};
`;

export const InterviewWrapper = styled.div`
  @media screen and ${device.desktop} {
    overflow-x: hidden;
    position: relative;
    width: 1050px;
    height: 350px;
  }
`;

export const Interviews = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transform-style: preserve-3d;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  transition: left 1s;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  div:first-of-type {
    margin-left: 20px;
  }
  div:last-of-type {
    margin-right: 20px;
  }
  @media screen and ${device.desktop} {
    position: absolute;
    left: 0;
    display: flex;
    div:first-of-type {
      margin-left: 0;
    }
    div:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Interview = styled.div`
  flex: 0 0 auto;
  width: 264px;
  height: 330px;
  margin-right: 16px;
  margin-left: 0;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
  justify-content: flex-start;
  @media ${device.desktop} {
    width: 340px;
    height: 350px;
    margin-right: 16px;
  }
  h3 {
    color: white;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.5;
    margin: 24px 0 16px;
    @media ${device.desktop} {
      font-size: 18px;
    }
  }
  p {
    color: white;
    font-family: Pretendard, "Nanum Gothic", serif;
    font-weight: 100;
    line-height: 1.4;
    font-size: 13px;
    letter-spacing: -0.56px;
    @media screen and ${device.desktop} {
      font-size: 16px;
      margin: 0;
    }
  }
  div {
    margin-top: auto;
  }
  button {
    width: 80%;
    cursor: pointer;
    align-items: center;
    padding: 10px 15px 10px 26px;
    background-color: #e4e4e4;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }
`;
export const Frame = styled.iframe`
  width: 264px;
  height: 148px;
  @media screen and ${device.desktop} {
    width: 332px;
    height: 187px;
  }
`;

export const Cards = styled.div`
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  grid-template-columns: 1fr;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  @media ${device.desktop} {
    max-width: 1230px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 44px auto 20px;
    gap: 35px 30px;
  }
`;
export const Card = styled.div`
  width: 321px;
  padding: 28px 20px 17.7px 21px;
  border-radius: 4px;
  background-image: linear-gradient(to top, #393939, rgba(29, 30, 31, 0));
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: space-between;
  font-size: 14px;
  letter-spacing: -0.48px;
  line-height: 1.6;
  color: #fff;
  word-break: break-all;
  white-space: pre-wrap;
  @media screen and ${device.desktop} {
    width: 584px;
    height: 229px;
    padding: 40px 50px;
    font-size: 16px;
    gap: 0;
  }
`;
export const CardContent = styled.div`
  @media screen and ${device.desktop} {
    font-size: 16px;
    letter-spacing: -0.48px;
    line-height: 1.6;
  }
`;
export const CardTitle = styled.div`
  font-weight: bold;
  @media screen and ${device.desktop} {
    font-size: 16px;
    letter-spacing: -0.48px;
    line-height: 1.6;
  }
`;
