import * as S from "./LandingPageSecondBodyOrganism.style";
import Image from "next/image";
import { landingSecondBodyData } from "../../../../models/landing/landing.models";

export default function LandingPageSecondBodyOrganism() {
  return (
    <S.Wrapper>
      <S.BackgroundImage>
        <Image
          src={`${process.env.CDN_BASE_URL}/hanghae99/hanghae-bg-stretched.jpg`}
          layout="fill"
          objectFit="cover"
          alt={"항해 배경"}
          priority={true}
        />
      </S.BackgroundImage>
      <S.Contents>
        <S.Title>개발자 취업이라는 신대륙을 향해</S.Title>
        <S.DescWrapper>
          {landingSecondBodyData.map((sen) => (
            <S.Desc key={sen}>{sen}</S.Desc>
          ))}
        </S.DescWrapper>
      </S.Contents>
    </S.Wrapper>
  );
}
