import * as S from "./LandingPageCareerSupportOrganism.style";

export default function LandingPageCareerSupportOrganism() {
  return (
    <S.Container>
      <S.SuppHeader>취업까지 함께 하는 커리어 서포트</S.SuppHeader>
      <S.Sups>
        <S.Sup>
          <S.PJT src={"/images/icons/ic_colab.svg"} alt="pjt" />
          <S.SuppTitle>기한없는 협력사 채용연계 기회</S.SuppTitle>
          <S.SuppText>
            <S.SuppTextDetail>
              최종 프로젝트 발표회에서 협력사들에게 나를 어필하고, 자체 채용
              플랫폼을 통해 수료 직후부터 기한 없이 <S.BrMobile />
              협력사로부터 채용 오퍼를 받을 수 있습니다.
            </S.SuppTextDetail>
            <S.SuppTextDetail>
              활발하게 채용중인 협력사의 CTO/CEO 취업 특강을 통해 기업에서
              원하는 인재상과, 취업에 필요한 자질을 알려드립니다.
            </S.SuppTextDetail>
          </S.SuppText>
        </S.Sup>
        <S.Sup>
          <S.PJT src="/images/icons/ic_recruit.svg" alt="pjt" />
          <S.SuppTitle>현직 개발자가 검토하는 이력서</S.SuppTitle>
          <S.SuppText>
            <S.SuppTextDetail>
              자기소개서, 이력서, 포트폴리오 작성을 위한 가이드를 <S.BrMobile />{" "}
              제공하며, 현직 개발자가 피드백을 제공합니다.
            </S.SuppTextDetail>
            <S.SuppTextDetail>
              항해에서 나만의 포트폴리오부터, 이력서 작성 가이드,
              <S.BrMobile />
              피드백까지 얻어가세요.
            </S.SuppTextDetail>
          </S.SuppText>
        </S.Sup>
        <S.Sup>
          <S.PJT src="/images/icons/ic_presentation.svg" alt="cmp" />
          <S.SuppTitle>기술면접 완벽대비</S.SuppTitle>
          <S.SuppText>
            <S.SuppTextDetail>
              실전 프로젝트 중반에 현직 개발자의{" "}
              <S.BrMobile />
              &apos;기술 챌린지 세션&apos;을 통해 날카로운 피드백을 받고,{" "}
              <S.BrMobile />
              도입하면 좋을 기술적인 도전을 설정합니다.
            </S.SuppTextDetail>
            <S.SuppTextDetail>
              현업 개발자와 기술 모의면접을 통해
              <S.BrMobile /> 기술면접을 효과적으로 준비하세요.
            </S.SuppTextDetail>
          </S.SuppText>
        </S.Sup>
      </S.Sups>
    </S.Container>
  );
}
