import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhMainColor } from "../../../../../styles/themes/variables";

export const Wrapper = styled.section`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  @media ${device.desktop} {
    display: none;
  }
`;

export const Contents = styled.div`
  display: flex;
`;

export const LogoutButton = styled.button`
  cursor: pointer;
  flex: 4;
  box-shadow: 0 0 0 1px #f50000 inset;
  background-color: #ffffff;
  color: ${hhMainColor};
  border-radius: 0;
  font-size: 14px;
  border: 1px solid ${hhMainColor};
  padding: 13px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin: 0;
`;

export const ApplyButton = styled(LogoutButton)`
  flex: 6;
  color: white;
  background-color: ${hhMainColor};
`;
