import styled from "@emotion/styled";
import {
  hhBlackColor,
  hhMainColor,
} from "../../../../../styles/themes/variables";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  height: 190px;
  flex-direction: column;
  justify-content: center;
  background-color: ${hhBlackColor};
  @media ${device.desktop} {
    height: 200px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 106px;
  justify-content: space-between;

  @media ${device.desktop} {
    width: 992px;
    height: 200px;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    flex-direction: row;
  }
`;

export const Text = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

export const Title = styled.h2`
  color: ${hhMainColor};
  font-size: 36px;
  margin: 0;
  margin-right: 8px;
  @media ${device.desktop} {
    font-size: 64px;
    margin-right: 20px;
  }
`;

export const Desc = styled.div`
  span:first-of-type {
    color: white;
    font-weight: 600;
    line-height: 1.5;
    font-size: 16px;
    @media ${device.desktop} {
      font-size: 24px;
    }
  }
  span:nth-of-type(2) {
    color: #8b8b8b;
    font-weight: bold;
    ine-height: 1.5;
    font-size: 12px;
    @media ${device.desktop} {
      font-size: 18px;
    }
  }
`;
