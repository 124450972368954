import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Container = styled.section`
  max-width: 320px;
  margin: auto;

  @media ${device.desktop} {
    max-width: 1230px;
  }
`;

export const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;

  @media ${device.desktop} {
    margin-top: 0;
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: row;
    &:last-child {
      flex-direction: row-reverse;

      .project_video_container {
        margin-right: 0;
        margin-left: 120px;
      }
    }
  }
`;

export const ProjectVideoContainer = styled.div`
  @media ${device.desktop} {
    margin-right: 120px;
  }
`;

export const ProjectTitleMobile = styled.div`
  font-family: "SpoqaHanSans";
  font-weight: bold;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.66px;
  text-align: left;
  color: #000000;

  @media ${device.desktop} {
    display: none;
  }

  span {
    word-spacing: -5px;
    font-size: inherit;
    font-weight: inherit;
  }
`;

export const Video = styled.video`
  width: 320px;
  height: auto;
  margin: 22px 0;
  @media ${device.desktop} {
    width: 560px;
    height: 315px;
    margin: 0;
  }
`;

export const Source = styled.source``;

export const Strong = styled.strong``;

export const ProjectDescContainer = styled.div``;

export const ProjectTitlePC = styled.div`
  display: none;

  span {
    word-spacing: -5px;
    font-family: "SpoqaHanSans";
    font-size: inherit;
    font-weight: inherit;
  }

  @media ${device.desktop} {
    display: block;
    font-family: "SpoqaHanSans";
    font-weight: bold;
    font-size: 31px;
    font-stretch: normal;
    letter-spacing: 1px;
    text-align: left;
    color: #000000;
    line-height: 1.4;
  }
`;

export const ProjectText = styled.div`
  font-family: "Pretendard", serif;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.05px;
  text-align: left;
  color: #000000;
  letter-spacing: -0.6px;

  @media ${device.desktop} {
    font-family: "Pretendard", serif;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: -0.64px;
    text-align: left;
    color: #000000;
    letter-spacing: 0px;
    margin-top: 8px;
    line-height: 1.5;
  }
`;

export const ProjectLinkBtn = styled.div`
  border-radius: 4px;
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  cursor: pointer;
  color: black;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  @media ${device.desktop} {
    margin-top: 16px;
    width: 200px;
    font-size: 15px;
  }
`;

export const Arrow = styled.div`
  width: 7.1px;
  height: 12px;
  background-image: url("/images/icons/ic_arrow.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(1);
  padding-right: 10px;
  margin-left: 5px;
  @media ${device.desktop} {
    height: 11.2px;
  }
`;
