import * as S from "./LandingPagePortOraganism.style";
import Image from "next/image";
import {
  landingPortData1,
  landingPortData2,
} from "../../../../models/landing/landing.models";
import { useInnerWidth } from "../../../../businessLogics/util-browser";

export default function LandingPagePortOraganism() {
  const innerWidth = useInnerWidth();
  return (
    <S.Wrapper>
      <S.Contents>
        {innerWidth > 1024 ? (
          <>
            <S.PortImg>
              <Image
                src={`${process.env.CDN_BASE_URL}/hanghae99/port99_img.png`}
                width={564}
                height={332}
              />
            </S.PortImg>
            <S.Text>
              <S.Title>
                취업생의 58.12%가 <br />
                협력사에 채용되었습니다.
              </S.Title>
              <S.Desc>{landingPortData1}</S.Desc>
              <S.Desc>{landingPortData2}</S.Desc>
            </S.Text>
          </>
        ) : (
          <>
            <S.Text>
              <S.Title>
                취업생의 58.12%가 <br />
                협력사에 채용되었습니다.
              </S.Title>
            </S.Text>
            <S.PortImg>
              <Image
                src={`${process.env.CDN_BASE_URL}/hanghae99/port99_img.png`}
                layout="responsive"
                width={1128}
                height={645}
              />
            </S.PortImg>
            <S.Desc>{landingPortData1}</S.Desc>
            <S.Desc>{landingPortData2}</S.Desc>
          </>
        )}
      </S.Contents>
    </S.Wrapper>
  );
}
