import * as S from "./LandingPageTemplate.style";
import { useEffect } from "react";
import { track } from "../../../../lib/facebook";
import { gtagReportConversion, marketingGtag } from "../../../../lib/gtag";
import { sendLog } from "../../../../lib/logging";
import { makeEventId } from "../../../businessLogics/utils/_helper";
import { useAtom } from "jotai";
import Portal from "../../atoms/Common/Portal";
import LandingPageOrientationModalOrganism from "../../organisms/LandingPageOranisms/LandingPageOrientationModalOrganism";

export default function LandingPageTemplate({
  lnb,
  intro,
  preApplyBanner,
  firstBody,
  secondBody,
  thirdBody,
  firstBanner,
  fourthBody,
  joinedCompanies,
  hanghaeDifference,
  bestPartners,
  port,
  mentor,
  curriculum,
  curriSprint,
  portfolios,
  careerSupport,
  // secondBanner,
  apply,
  lecturePrice,
  companyRecommend,
  question,
  footer,
  floatingCta,
}) {
  // 로그 관련 useEffect
  useEffect(() => {
    const eventId = makeEventId();
    track("ViewContent", { content_category: "항해" }, eventId);
    marketingGtag("view_item", {
      id: "hanghae99",
    });
    gtagReportConversion("FkEDCMPSvvEBENiI190C");
    sendLog("hh_mainPage_view", {});
  }, []);

  return (
    <>
      <Portal selector={"#portal"}>
        <LandingPageOrientationModalOrganism />
      </Portal>
      <S.Wrapper>
        {lnb}
        {intro}
        {preApplyBanner}
        {firstBody}
        {secondBody}
        {thirdBody}
        {firstBanner}
        {fourthBody}
        {joinedCompanies}
        {hanghaeDifference}
        {bestPartners}
        {port}
        {mentor}
        {curriculum}
        {curriSprint}
        {portfolios}
        {careerSupport}
        {/* {secondBanner} */}
        {apply}
        {lecturePrice}
        {companyRecommend}
        {question}
        {footer}
        {floatingCta}
      </S.Wrapper>
    </>
  );
}
