import * as S from "./LandingPagePreApplyBannerOrganism.style";
import LandingPagePreApplyBannerCards from "../../../molecules/LandingPageMolcules/LandingPagePreApplyBannerCards";

export default function LandingPagePreApplyBannerOrganism() {
  return (
    <S.Wrapper>
      <S.TextIcon>얼리버드 혜택 진행중</S.TextIcon>
      <S.BannerTitle>항해99 사전스터디 혜택!</S.BannerTitle>
      <LandingPagePreApplyBannerCards />
    </S.Wrapper>
  );
}
