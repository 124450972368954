import styled from "@emotion/styled";
import {
  fontPretendard,
  fontSpoqaHanSans,
} from "../../../../styles/themes/variables";

export const Wrapper = styled.section`
  font-family: ${fontPretendard};
  h1,
  h2,
  h3 {
    font-family: ${fontSpoqaHanSans};
  }
  overflow: hidden;
`;
