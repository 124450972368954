import * as S from "./LandingPageFirstBodyOrganism.style";
import { landingFirstBodyData } from "../../../../models/landing/landing.models";

export default function LandingPageFirstBodyOrganism(props) {
  return (
    <S.Wrapper id="소개">
      <S.Contents>
        <S.Text>
          <S.Title>지금 출항합니다.</S.Title>
          {landingFirstBodyData.map((sen) => (
            <S.Desc key={sen}>{sen}</S.Desc>
          ))}
        </S.Text>
        <S.Video>
          <video
            src={`${process.env.CDN_BASE_URL}/hanghae99/new_main.mp4`}
            width="100%"
            muted
            autoPlay
            playsInline
            controls
            controlsList="nodownload"
          />
        </S.Video>
      </S.Contents>
    </S.Wrapper>
  );
}
