import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 123px;
  @media screen and ${device.desktop}{
    height: 239px;
  }
`;
export const ImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
export const Title = styled.div`
  display:none;
  font-family: "SpoqaHanSans", "Nanum Gothic", serif;
  font-weight: bold;
  font-size: 31px;
  color: #fff;
  @media screen and ${device.desktop}{
    display: block;
  }
`;
export const Cta = styled.a`
  width: 230px;
  height: 40px;
  border: none;
  font-size: 14px;
  padding: 9px 24px;
  background-color: #e4e4e4;
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  @media ${device.desktop} {
    font-size: 16px;
  }
`;
