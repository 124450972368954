import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  background-color: #f1f1f1;
  padding: 32px 20px;
  @media ${device.desktop} {
    padding: 40px 0;
  }
`;

export const Contents = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 1230px;
  }
`;

export const PortImg = styled.div`
  margin: 24px 0;
  @media ${device.desktop} {
    margin-right: 98px;
  }
`;

export const Text = styled.div``;

export const Title = styled.h3`
  margin: 0;
  font-size: 22px;
  line-height: 1.5;
  @media ${device.desktop} {
    font-size: 32px;
    margin-bottom: 19px;
  }
`;

export const Desc = styled.p`
  white-space: pre-wrap;
  margin: 0 0 8px 0;
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.64px;
    margin: 0;
    width: 498px;
    margin: 0 0 11px 0;
  } ;
`;
