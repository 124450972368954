import * as S from "./LandingPageQuestionOrganism.style";
import Image from "next/image";

import arrow from "/static/images/icons/arrow_next_black_bold.svg";
const LandingPageQuestionOrganism = () => {
  return (
    <S.Container>
      <S.ImageWrapper>
        <Image
          src={`${process.env.CDN_BASE_URL}/hanghae99/hanghae-bg-stretched.jpg`}
          layout={"fill"}
          alt={"항해 배경"}
          quality={100}
          priority={true}
        />
      </S.ImageWrapper>
      <S.Content>
        <S.Title>99일간의 항해로, 개발자로 거듭나세요.</S.Title>
        <S.Cta
          href="https://teamsparta.notion.site/99-FAQs-0d57a6a377de4dfbb9403e157ebb5a9a"
          target="_blank"
          rel="noreferrer"
        >
          자주 묻는 질문 보러가기
          <Image src={arrow} width={20} height={20} />
        </S.Cta>
      </S.Content>
    </S.Container>
  );
};
export default LandingPageQuestionOrganism;
