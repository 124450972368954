import * as S from "./LandingPageCompanyRecommendOrganism.style";
import {
  CarouselNextArrowPathProps,
  CarouselNextArrowSVGProps,
  CarouselPrevArrowPathProps,
  CarouselPrevArrowSVGProps,
  LandingCompanyRecommend,
  RecommendCardContent,
} from "../../../../models/landing/landing.models";
import { useState } from "react";

const LandingPageCompanyRecommendOrganism = () => {
  const [slideCount, setSlideCount] = useState(0);
  const maximumSlideNum = LandingCompanyRecommend.length - 3;
  const isPrevActive = slideCount > 0;
  const isNextActive = slideCount < maximumSlideNum;
  const moveCarousel = (isNextDirection: boolean) => {
    if (isNextDirection) {
      setSlideCount(slideCount + 1);
    } else {
      setSlideCount(slideCount - 1);
    }
  };

  const handleClickPrevArrow = () => {
    if (isPrevActive) {
      moveCarousel(false);
    }
  };

  const handleClickNextArrow = () => {
    if (isNextActive) {
      moveCarousel(true);
    }
  };

  const getReviewsStyle = () => {
    return { left: -(slideCount * 358) };
  };
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>추천사</S.Title>
        <S.Description>
          시니어 개발자와 대표가 인정한 99일간의 프로그램
        </S.Description>
      </S.TitleContainer>
      <S.Carousel>
        <S.Arrow isActive={isPrevActive} onClick={handleClickPrevArrow}>
          <S.ArrowSVG {...CarouselPrevArrowSVGProps}>
            <S.ArrowPath
              isActive={isPrevActive}
              {...CarouselPrevArrowPathProps}
            />
          </S.ArrowSVG>
        </S.Arrow>
        <S.InterviewWrapper>
          <S.Interviews style={getReviewsStyle()}>
            {LandingCompanyRecommend.map((interview) => (
              <S.Interview key={interview.url}>
                <S.Frame
                  src={interview.url}
                  frameBorder={0}
                  allowFullScreen={true}
                  loading="lazy"
                />
                <h3>{interview.title}</h3>
                <p>{interview.desc}</p>
              </S.Interview>
            ))}
          </S.Interviews>
        </S.InterviewWrapper>
        <S.Arrow isActive={isNextActive} onClick={handleClickNextArrow}>
          <S.ArrowSVG {...CarouselNextArrowSVGProps}>
            <S.ArrowPath
              isActive={isNextActive}
              {...CarouselNextArrowPathProps}
            />
          </S.ArrowSVG>
        </S.Arrow>
      </S.Carousel>
      <S.Cards>
        {RecommendCardContent.map((content, idx) => (
          <S.Card key={"랜딩 협력사 추천 카드" + idx}>
            <S.CardContent>{content.Description}</S.CardContent>
            <S.CardTitle>{content.Title}</S.CardTitle>
          </S.Card>
        ))}
      </S.Cards>
    </S.Container>
  );
};

export default LandingPageCompanyRecommendOrganism;
