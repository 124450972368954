import * as S from "./Footer.style";
import Image from "next/image";
import { useIsMobile } from "../../../businessLogics/util-browser";

export default function Footer(props) {
  const isMobile = useIsMobile();
  return (
    <S.Wrapper>
      <S.Header>
        <S.SiteMapWrapper>
          <h4>SiteMap</h4>
          <S.SiteMap>
            <a
              href={"https://spartacodingclub.kr/"}
              target={"_blank"}
              rel="noreferrer"
            >
              스파르타코딩클럽
            </a>
            <a
              href={"https://port99.spartacodingclub.kr/"}
              target={"_blank"}
              rel="noreferrer"
            >
              개발자 채용 플랫폼
            </a>
            <a
              href={"https://hanghae99-v2.spartacodingclub.kr/v2/exhibitions"}
              target={"_blank"}
              rel="noreferrer"
            >
              수강생 작품
            </a>
          </S.SiteMap>
        </S.SiteMapWrapper>
        <S.ApplyWrapper>
          <h4>Apply</h4>
          <S.ApplyItem>
            <a
              href={"https://hanghae99-v2.spartacodingclub.kr/v2/application/1"}
              target={"_blank"}
              rel="noreferrer"
            >
              지원서 작성
            </a>
            <a
              href={
                "https://docs.google.com/forms/d/e/1FAIpQLSdsDjX4sGK77k50ol89lYJUJ2c_Csl2TE-a_RCVV1Jq1012bw/viewform"
              }
              target={"_blank"}
              rel="noreferrer"
            >
              협력사 지원
            </a>
          </S.ApplyItem>
        </S.ApplyWrapper>
        <S.ButtonWrapper>
          <S.ImageButton
            onClick={() =>
              window.open("https://spartacodingclub.kr/blog?category=hanghae")
            }
          >
            <Image
              src={"/static/images/footer/naver.png"}
              width={30}
              height={30}
              alt={"blog"}
            />
          </S.ImageButton>
          <S.ImageButton
            onClick={() => window.open("https://www.instagram.com/hanghae99/")}
          >
            <Image
              src={"/static/images/footer/instagram.png"}
              width={30}
              height={30}
              alt={"instagram"}
            />
          </S.ImageButton>
          <S.ImageButton
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCi2LvS1cmM8mCZwBC1DiOPg/featured"
              )
            }
          >
            <Image
              src={"/static/images/footer/youtube.png"}
              width={30}
              height={30}
              alt={"youtube"}
            />
          </S.ImageButton>
        </S.ButtonWrapper>
      </S.Header>
      <S.Terms>
        <a
          href={
            "https://teamsparta.notion.site/7b1dc644460946f08bab08b794de685f"
          }
          target={"_blank"}
          rel="noreferrer"
        >
          개인정보처리방침
        </a>
        <a
          href={
            "https://teamsparta.notion.site/247d57da1322424d8e8c551df21a048e"
          }
          target={"_blank"}
          rel="noreferrer"
        >
          서비스 이용 약관
        </a>
        <a
          href={
            "https://teamsparta.notion.site/e4504ceeadd34f21b0ba767b30a7fdfb"
          }
          target={"_blank"}
          rel="noreferrer"
        >
          환불 규정
        </a>
        {/*<span>고객 문의</span>*/}
      </S.Terms>
      <S.CompanyInfo>
        <h4>팀스파르타(주) 사업자 정보</h4>
        <S.CompanyDetail>
          대표자: 이범규
          {isMobile ? <br /> : ` | `}
          사업자 등록번호: 783-86-01715
          {isMobile ? <br /> : ` | `}
          통신판매업 신고번호: 2020-서울강남-02300
          {isMobile ? <br /> : ` | `}
          평생교육시설 신고번호: 제 661호
          <br />
          주소: 서울특별시 강남구 테헤란로44길 8 12층
          {isMobile ? <br /> : ` | `}
          (아이콘역삼빌딩)
          {isMobile ? <br /> : ` | `}
          이메일: contact@teamsparta.co
          {isMobile ? <br /> : ` | `}
          전화: 1522-8016
          {/*{isMobile ? <br /> : ` | `}*/}
          {/*빠른상담 : 홈페이지 채팅문의버튼*/}
          <S.CopyrightInfo>
            Copyright ©2022 TEAMSPARTA. All rights reserved.
          </S.CopyrightInfo>
        </S.CompanyDetail>
      </S.CompanyInfo>
    </S.Wrapper>
  );
}
