import * as S from "./LandingPageCurriSprintOrganism.style";
import { useEffect, useRef } from "react";

export default function LandingPageCurriSprintOrganism() {
  const organRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    //TODO: scroll debounce
    window.addEventListener("scroll", playVideoWhenWatching);
    return () => window.removeEventListener("scroll", playVideoWhenWatching);
  }, []);

  function playVideoWhenWatching() {
    const isAllRendered =
      videoRef && videoRef.current && organRef && organRef.current;
    if (!isAllRendered) return;

    const startPointOfDiv = organRef.current.offsetTop;
    const endPointOfDiv = startPointOfDiv + organRef.current.offsetHeight;
    const myPoint = window.pageYOffset + window.innerHeight;

    if (myPoint >= startPointOfDiv) {
      videoRef.current.play();
      if (myPoint >= endPointOfDiv) {
        window.removeEventListener("scroll", playVideoWhenWatching);
      }
    }
  }

  return (
    <S.Container>
      <S.ProjectInfoContainer ref={organRef}>
        <S.ProjectVideoContainer className="project_video_container">
          <S.ProjectTitleMobile>
            99일 동안 <span>9 to 9</span>으로, <br /> 현업과 같은 스프린트
            형태로 진행됩니다.
          </S.ProjectTitleMobile>
          <S.Video
            ref={videoRef}
            muted
            loop
            playsInline
            controls
            controlsList={"nodownload"}
          >
            <S.Source
              src="https://static.spartacodingclub.kr/hanghae99/introduction.mp4"
              type="video/mp4"
            />
            <S.Strong>Your browser does not support the video tag</S.Strong>
          </S.Video>
        </S.ProjectVideoContainer>
        <S.ProjectDescContainer>
          <S.ProjectTitlePC>
            99일 동안 <span>9 to 9</span>으로,
            <br /> 현업과 같은 스프린트 형태로 진행됩니다.
          </S.ProjectTitlePC>
          <S.ProjectText>
            현업의 개발자들처럼, 단기간에 프로토타입을 제작, 회고하는 과정을
            반복합니다.
            <br />
            첫째 날은 목표를 받아, 스케치를 통해 아이디어를 구체화합니다. 마지막
            날은 결과물을 발표하고, 멘토와 함께 회고를 진행합니다. 팀이 올바른
            방향으로 갈 수 있도록, 중간에는 피드백 및 면담이 이루어집니다.
            <br />
            높은 밀도의 커뮤니케이션과 집중력을 길러내는 과정이기에, 한 반의 90%
            (50명 중 45명)가 수료하고 있습니다. 끈기 있는 분들이라면 해내실 수
            있습니다. 동료들과 함께하는 일상을 살펴보세요.
          </S.ProjectText>
          <S.ProjectLinkBtn onClick={()=>window.open("https://teamsparta.notion.site/99-4062952a66814dadab7c88e0dcbd434c")}>
              수강생 일상 보러가기
              <S.Arrow />
          </S.ProjectLinkBtn>
        </S.ProjectDescContainer>
      </S.ProjectInfoContainer>
    </S.Container>
  );
}
