import * as S from "./LandingPagePortfoliosOrganism.style";
import {
  CarouselNextArrowPathProps,
  CarouselNextArrowSVGProps,
  CarouselPrevArrowPathProps,
  CarouselPrevArrowSVGProps,
  landingInterviewData,
} from "../../../../models/landing/landing.models";
import { useState } from "react";
import { useInnerWidth } from "../../../../businessLogics/util-browser";

class Portfolio {
  constructor(
    public videoURL: string,
    public title: string,
    public desc: string
  ) {}
}

export default function LandingPagePortfoliosOrganism() {
  const PORTFOLIOS: Portfolio[] = [
    new Portfolio(
      "https://www.youtube.com/embed/K7K6FA7INMs",
      "외출난이도",
      "런칭 1주일 만에 3,000명 이상이 방문한 서비스"
    ),
    new Portfolio(
      "https://www.youtube.com/embed/Q2pUbG6HHnw",
      "모코모코",
      "유료 서비스화 제안을 받은 서비스"
    ),
    new Portfolio(
      "https://www.youtube.com/embed/qVoktqvB7e4",
      "InSplace",
      "팀원 대다수가 스카우트된 서비스"
    ),
    new Portfolio(
      "https://www.youtube.com/embed/nnKf3lMiy0c",
      "Hang",
      "백엔드 보안 강화에 집중한 서비스"
    ),
    new Portfolio(
      "https://www.youtube.com/embed/iGXQKHdxAOw",
      "Habit Monster",
      "키치한 디자인이 특징인 서비스"
    ),
    new Portfolio(
      "https://www.youtube.com/embed/y3itBLnabnk",
      "뽀모모",
      "타이머, 화상스터디 기능으로 프론트엔드가 강조된 서비스"
    ),
  ];
  const innerWidth = useInnerWidth();
  const [slideCount, setSlideCount] = useState(0);
  const maximumSlideNum = PORTFOLIOS.length - 3;
  const isPrevActive = slideCount > 0;
  const isNextActive = slideCount < maximumSlideNum;
  const moveCarousel = (isNextDirection: boolean) => {
    if (isNextDirection) {
      setSlideCount(slideCount + 1);
    } else {
      setSlideCount(slideCount - 1);
    }
  };

  const handleClickPrevArrow = () => {
    if (isPrevActive) {
      moveCarousel(false);
    }
  };

  const handleClickNextArrow = () => {
    if (isNextActive) {
      moveCarousel(true);
    }
  };

  const getReviewsStyle = () => {
    return { left: -(slideCount * 358) };
  };

  return (
    <S.Container>
      <S.ContentLayer>
        <S.PortfolioHeader>
          <S.PortfolioHeaderTitle>
            실제 서비스를 런칭해본 <S.BrMobile /> 개발자로 거듭납니다.
          </S.PortfolioHeaderTitle>
          <S.PortfolioHeaderDesc>
            프론트엔드와 백엔드 팀원들이 디자이너와 협업해 런칭한 서비스입니다.{" "}
            <br />
            3주간 MVP를 구현하고, 2주간 사용자 테스트를 바탕으로 서비스를
            운영/개선하는 경험을 통해 나만의 포트폴리오, 취업 경쟁력을 갖습니다.
          </S.PortfolioHeaderDesc>
        </S.PortfolioHeader>
        <S.Carousel>
          <S.Arrow isActive={isPrevActive} onClick={handleClickPrevArrow}>
            <S.ArrowSVG {...CarouselPrevArrowSVGProps}>
              <S.ArrowPath
                isActive={isPrevActive}
                {...CarouselPrevArrowPathProps}
              />
            </S.ArrowSVG>
          </S.Arrow>
          <S.PortfolioWrapper>
            <S.Portfolios style={getReviewsStyle()}>
              {PORTFOLIOS.map((portfolio) => (
                <S.Portfolio key={portfolio.title}>
                  <iframe
                    src={portfolio.videoURL}
                    width={innerWidth < 1024 ? 264 : 332}
                    height={innerWidth < 1024 ? 148 : 187}
                    frameBorder={0}
                    allowFullScreen={true}
                    loading="lazy"
                  />
                  <h3>{portfolio.title}</h3>
                  <p>{portfolio.desc}</p>
                </S.Portfolio>
              ))}
            </S.Portfolios>
          </S.PortfolioWrapper>
          <S.Arrow isActive={isNextActive} onClick={handleClickNextArrow}>
            <S.ArrowSVG {...CarouselNextArrowSVGProps}>
              <S.ArrowPath
                isActive={isNextActive}
                {...CarouselNextArrowPathProps}
              />
            </S.ArrowSVG>
          </S.Arrow>
        </S.Carousel>
        <S.PortfolioFooter>
          <S.PortfolioBtn
            href="https://hanghae99-v2.spartacodingclub.kr/v2/exhibitions"
            target="_blank"
          >
            수강생 작품 모아보기
            <img src={"/images/icons/ic_button_next.png"} alt={"ic_arrow"} />
          </S.PortfolioBtn>
        </S.PortfolioFooter>
      </S.ContentLayer>
    </S.Container>
  );
}
