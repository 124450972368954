import * as S from "./LandingPageThirdBodyOrganism.style";
import { landingThirdBodyData } from "../../../../models/landing/landing.models";
import Image from "next/image";

export default function LandingPageThirdBodyOrganism() {
  return (
    <S.Wrapper>
      <S.Contents>
        <S.Title>
          항해를 마치면, <br />
          이런 개발자가 됩니다.
        </S.Title>
        <S.Images>
          {landingThirdBodyData.map((data) => (
            <S.Card key={data.img}>
              <S.Image>
                <Image src={data.img} layout="fill" />
              </S.Image>
              <h2>{data.title}</h2>
              <p>{data.desc}</p>
            </S.Card>
          ))}
        </S.Images>
      </S.Contents>
    </S.Wrapper>
  );
}
