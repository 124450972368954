import styled from "@emotion/styled";
import { hhBlackColor } from "../../../../../styles/themes/variables";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  background-color: ${hhBlackColor};
  padding: 40px 0 64px;
  @media ${device.desktop} {
    padding: 64px 0;
  }
`;

export const Contents = styled.div`
  max-width: 320px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.desktop} {
    flex-direction: row;
    max-width: 1230px;
    align-items: start;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    width: 525px;
  }
`;

export const Title = styled.h1`
  color: white;
  font-size: 22px;
  margin: 0;
  padding-bottom: 16px;
  line-height: 1.4;
  @media ${device.desktop} {
    font-size: 31px;
  }
`;

export const Desc = styled.p`
  color: white;
  font-weight: 100;
  font-size: 14px;
  margin: 0;
  padding-bottom: 12px;

  line-height: 1.6;
  @media ${device.desktop} {
    font-size: 16px;
    line-height: 1.4;
    word-break: keep-all;
  }
`;

export const Video = styled.div`
  width: 320px;
  height: 181px;
  margin-top: 40px;
  @media ${device.desktop} {
    width: 635px;
    height: 360px;
    padding: 0;
    margin: 0;
  }
`;
