import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Wrapper = styled.section`
  background-color: #742727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  @media ${device.desktop} {
    padding: 48px 0;
  }
`;

export const TextIcon = styled.strong`
  color: white;
  background: #cc1e1e;
  border-radius: 24px;
  padding: 6px 13px;
  font-weight: 600;
  letter-spacing: -0.01em;
  @media ${device.desktop} {
    font-size: 18px;
    line-height: 120%;
  }
`;

export const BannerTitle = styled.h2`
  color: white;
  font-size: 24px;
  line-height: 144%;
  letter-spacing: 0.02em;
  margin: 8px 0;
  font-weight: 700;
  @media ${device.desktop} {
    margin: 12px 0 8px 0;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: 0.02em;
    font-weight: 600;
  }
`;
