import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";
import { hhMainColor } from "../../../../../styles/themes/variables";

export const Wrapper = styled.section<{ scrollY: number }>`
  height: 40px;
  background-color: ${(props) => (props.scrollY > 0 ? "#ffffffcc" : "")};
  position: fixed;
  z-index: 50;
  width: 100%;
  transition: all 0.4s ease 0s;
  top: 0;
  @media ${device.desktop} {
    height: 72px;
  }
`;

export const LnbContents = styled.div`
  height: inherit;
  align-items: center;
  margin: 0 auto;
  max-width: 320px;

  @media ${device.desktop} {
    width: inherit;
    max-width: 1230px;
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
`;

export const Logo = styled.div<{ scrollY: number }>`
  cursor: pointer;
  padding-top: 10px;
  width: 60px;
  display: ${(props) => (props.scrollY < 532 ? "" : "none")};
  margin: 0 auto;
  @media ${device.desktop} {
    padding: 0;
    width: 117px;
    display: inherit;
    margin: 0;
  }
`;

export const MobileContents = styled.div<{ scrollY: number }>`
  height: 40px;
  display: ${(props) => (props.scrollY < 532 ? "none" : "flex")};
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  margin: auto;
  @media ${device.desktop} {
    display: none;
  }
`;

export const RightSide = styled.div<{ scrollY: number }>`
  font-size: 14px;
  display: none;
  @media ${device.desktop} {
    display: ${(props) => (props.scrollY > 0 ? "block" : "")};
  }
`;

export const Contents = styled.span<{
  scrollY: number;
  text: string;
  active: boolean;
}>`
  padding: 12px 13px 10px;
  line-height: 1;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -1.12px;
  color: ${(props) => (props.active ? "#212529" : "#868e96")};
  border-bottom: ${(props) => (props.active ? "5px solid red" : "")};

  @media ${device.desktop} {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #212529;
    text-align: inherit;
    letter-spacing: inherit;
    padding: 0 15px 23px;
  }
`;

export const LnbButton = styled.button<{ children }>`
  cursor: pointer;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  color: ${(props) =>
    props.children.includes("로그아웃") ? `${hhMainColor}` : "white"};
  background-color: ${(props) =>
    props.children.includes("로그아웃") ? "white" : `${hhMainColor}`};
  box-shadow: ${(props) =>
    props.children.includes("로그아웃") ? "0 0 0 2px #f50000 inset" : ""};
  @media ${device.desktop} {
    margin-left: ${(props) =>
      props.children.includes("로그아웃") ? "50px" : "20px"};
    padding: 11.5px 47.5px;
  }
`;
