import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Container = styled.section`
  padding: 40px 0;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.81) 100%
    ),
    linear-gradient(to bottom, #233841, #233841);
  @media ${device.desktop} {
    padding: 80px 0;
  }
`;

export const ContentLayer = styled.div`
  max-width: 1230px;
  margin: auto;
`;

export const PortfolioHeader = styled.div`
  margin: 0 auto 24px auto;
  max-width: 320px;
  @media ${device.desktop} {
    text-align: center;
    max-width: 1230px;
    margin-bottom: 40px;
  }
`;

export const PortfolioHeaderTitle = styled.h2`
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 0.66px;
  font-weight: bold;
  line-height: 1.45;
  margin: 0 0 8px 0;
  font-family: "SpoqaHanSans";
  //white-space: pre-line;
  @media ${device.desktop} {
    font-size: 32px;
    margin-top: 0;
  }
`;

export const BrMobile = styled.br`
  @media ${device.desktop} {
    display: none;
  }
`;

export const PortfolioHeaderDesc = styled.p`
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.06px;
  color: #f1f1f1;
  font-weight: 300;
  font-family: "Pretendard", serif;
  @media ${device.desktop} {
    font-size: 16px;
  }
`;

export const PortfolioFooter = styled.div`
  max-width: 320px;
  margin: auto;
  @media ${device.desktop} {
    margin-top: 20px;
    max-width: 226px;
  }
`;

export const PortfolioBtn = styled.a`
  width: 100%;
  height: 42px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  background: #f50000;
  @media ${device.desktop} {
    height: 48px;
  }

  img {
    margin-left: 10px;
    width: 18px;
  }
`;

export const Carousel = styled.div`
  @media ${device.desktop} {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const Arrow = styled.div<{ isActive: boolean }>`
  display: none;

  @media ${device.desktop} {
    display: inherit;
  }

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 78px;
  height: 50%;
  padding: 0 15px;

  cursor: ${(props) => (props.isActive ? "pointer" : "default")};

  text-align: center;

  :before,
  :after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
`;

export const ArrowSVG = styled.svg``;

export const ArrowPath = styled.path<{ isActive: boolean }>`
  stroke: ${(props) => (props.isActive ? "white" : "#888888")};
`;

export const PortfolioWrapper = styled.div`
  @media ${device.desktop} {
    overflow-x: hidden;
    position: relative;
    width: 1050px;
    height: 300px;
  }
`;

export const Portfolios = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transform-style: preserve-3d;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  transition: left 1s;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  div:first-of-type {
    margin-left: 20px;
  }
  div:last-of-type {
    margin-right: 20px;
  }
  @media ${device.desktop} {
    position: absolute;
    left: 0;
    display: flex;
    div:first-of-type {
      margin-left: 0;
    }
    div:last-of-type {
      margin-right: 0;
    }
  }
`;

export const Portfolio = styled.div`
  flex: 0 0 auto;
  width: 264px;
  margin-right: 16px;
  margin-left: 0;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;
  justify-content: flex-end;
  @media ${device.desktop} {
    width: 334px;
    margin-right: 24px;
  }
  h3 {
    color: white;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.5;
    margin: 16px 0 0;
    @media ${device.desktop} {
      font-size: 18px;
      margin: 24px 0 16px;
    }
  }
  p {
    color: white;
    font-weight: 300;
    line-height: 1.4;
    margin: 5px 0 20px;
    font-size: 13px;
    @media ${device.desktop} {
      font-size: 16px;
      margin: 0;
    }
  }
  div {
    margin-top: auto;
  }
  button {
    width: 80%;
    cursor: pointer;
    align-items: center;
    padding: 10px 15px 10px 26px;
    background-color: #e4e4e4;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
  }
`;
