import styled from "@emotion/styled";
import { device } from "../../../../../styles/themes";

export const Container = styled.div`
  max-width: 1230px;
  margin: auto 20px;
  padding: 40px 0 10px 0;
  @media ${device.desktop} {
    margin: auto;
    padding: 80px 0 60px 0;
  }
`;

export const SuppHeader = styled.div`
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.66px;
  text-align: center;
  color: #000000;

  @media ${device.desktop} {
    font-family: "SpoqaHanSans";
    font-size: 31px;
    font-stretch: normal;
    letter-spacing: 1px;
    text-align: left;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Sups = styled.div`
  @media ${device.desktop} {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: space-between;
  }
`;

export const Sup = styled.div`
  height: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  padding: 0 16px;
  border-radius: 8px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.12);
  @media ${device.desktop} {
    padding: 56px 32px;
    border-radius: 8px;
    background-color: #ffffff;
    align-items: flex-start;
    width: 368px;
    height: 379px;
    box-sizing: border-box;
    margin: 30px auto 20px auto;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.12);
  }
`;

export const PJT = styled.img`
  height: 64px;
  @media ${device.desktop} {
    height: 54px;
  }
`;

export const SuppTitle = styled.div`
  font-family: "SpoqaHanSans", serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: left;
  color: #000000;
  margin-top: 10px;

  @media ${device.desktop} {
    font-family: "SpoqaHanSans", serif;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #000000;
    font-size: 19px;
    margin-top: 25px;
  }
`;

export const SuppText = styled.div`
  font-family: "Pretendard", serif;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.05px;
  color: #000000;
  margin: 10px 0;
  text-align: center;

  @media ${device.desktop} {
    height: 135px;
    font-family: "Pretendard", serif;
    font-size: 16px;
    line-height: 1.6;
    text-align: left;
    color: #000000;
    margin: 20px 0 0 0;
    letter-spacing: -1px;
  }
`;

export const SuppTextDetail = styled.p`
  font-family: "Pretendard", serif;
  margin: 0;
`;

export const BrMobile = styled.br`
  @media ${device.desktop} {
    display: none;
  }
`;
