import * as S from "./LandingPageJoinedCompaniesOrganism.style";
import {
  landingJoinedCompaniesData1,
  landingJoinedCompaniesData2,
} from "../../../../models/landing/landing.models";
import Image from "next/image";
import arrow_next_black from "/static/images/icons/arrow_next_black.svg";
import { useState } from "react";
import { useInnerWidth } from "../../../../businessLogics/util-browser";

export default function LandingPageJoinedCompaniesOrganism() {
  const [showMoreComapny, setShowMoreCompany] = useState(false);
  const innerWidth = useInnerWidth();
  const showMoreButtonHandler = () => {
    return showMoreComapny
      ? window.open(
          "https://teamsparta.notion.site/99-681b8237208d4bc6ac732f6c05f19bc9",
          "_blank"
        )
      : setShowMoreCompany(true);
  };
  return (
    <S.Wrapper>
      <S.Contents>
        <S.Title>
          최고 수준의 IT 기업으로 <br />
          합류하고 있습니다.
        </S.Title>
        {innerWidth > 1024 ? (
          <>
            <S.CompanyWrapper>
              {landingJoinedCompaniesData1.map((company) => (
                <S.Company key={company.title}>
                  <h3>{company.title}</h3>
                  <S.Logo>
                    <Image src={company.img} layout="intrinsic" />
                  </S.Logo>
                  <p>{company.desc1}</p>
                  <p>{company.desc2}</p>
                </S.Company>
              ))}
            </S.CompanyWrapper>
            {showMoreComapny && (
              <S.CompanyWrapper>
                {landingJoinedCompaniesData2.map((company) => (
                  <S.Company key={company.title}>
                    <h3>{company.title}</h3>
                    <S.Logo>
                      <Image src={company.img} layout="intrinsic" />
                    </S.Logo>
                    <p>{company.desc1}</p>
                    <p>{company.desc2}</p>
                  </S.Company>
                ))}
              </S.CompanyWrapper>
            )}
            <S.MoreButton onClick={showMoreButtonHandler}>
              {showMoreComapny ? "후기 블로그 보러가기" : "후기 더보기"}
              <S.DropdownIcon showMoreComapny={showMoreComapny}>
                <Image src={arrow_next_black} />
              </S.DropdownIcon>
            </S.MoreButton>
          </>
        ) : (
          <>
            <S.CompanyWrapper>
              {[
                ...landingJoinedCompaniesData1,
                ...landingJoinedCompaniesData2,
              ].map((company) => (
                <S.Company key={company.title}>
                  <h3>{company.title}</h3>
                  <S.Logo>
                    <Image src={company.img} layout="intrinsic" />
                  </S.Logo>
                  <p>{company.desc1}</p>
                  <p>{company.desc2}</p>
                </S.Company>
              ))}
            </S.CompanyWrapper>
            <S.ButtonWrapper>
              <S.MoreButton onClick={showMoreButtonHandler}>
                후기 블로그 보러가기
                <S.DropdownIconMobile>
                  <Image src={arrow_next_black} />
                </S.DropdownIconMobile>
              </S.MoreButton>
            </S.ButtonWrapper>
          </>
        )}
      </S.Contents>
    </S.Wrapper>
  );
}
